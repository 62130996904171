import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { CtaBlock } from 'stile-us/src/components/blocks/CtaBlock';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

import { NextPageBlock } from 'components/blocks/NextPageBlock';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="Resources coming soon"
      description="Thanks for bearing with us"
      slug={props.location.pathname}
    />
  );
}

function HomePage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <HeroBlock title="Resources coming soon" text="Thanks for bearing with us" />

      <NextPageBlock path="what-is-stile/stile-classroom" />

      <CtaBlock />
    </PageLayout>
  );
}

export default HomePage;
